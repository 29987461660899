@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Seymour+One&display=swap");

html {
  overflow-x: hidden;
}

h1,
h3 {
  font-weight: 200;
}

.img-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.gallery-header-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -8rem;
  background-color: #ffff !important;
}

.gallery-header-section * {
  width: 60%;
  text-align: center;
}

.gallery-header {
  font-size: 2rem;
  line-height: 54px;
  color: #000000;
  font-family: "Inter", sans-serif;
}

.gallery-header-para {
  line-height: 1.9rem;
  color: #3d3e46;
  font-weight: 600;
}

.background-image-dots {
  background-image: url(https://t4.ftcdn.net/jpg/03/20/59/61/360_F_320596186_eFYMjkK769jB7qXTXF6aP9lXNftz0VCU.jpg);
  background-position: 0 0;
  background-size: 273px;
  background-repeat: no-repeat;
  background-position: 5% 0%;
}

.gallery-content-padding img {
  box-shadow: rgb(0 0 0 / 64%) 0px 4px 12px;
}

.gallery-content-padding {
  padding: 5rem 10%;
  font-family: "Quicksand", sans-serif;
}

.content-warper {
  display: flex;
  gap: 2rem;
}

.gallery-content-header {
  font-size: 1.5rem;
  font: 600 !important;
  color: rgb(0, 0, 0);
  margin-bottom: 1rem;
}

.single-image-heading {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  color: rgb(0, 0, 0);
}

.content-text-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-text-side,
.content-image-side {
  width: 50%;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.gallery-content-para {
  line-height: 24px !important;
  color: #3d3e46;
}

.row {
  display: -ms-flexbox;
  display: flex;
  padding: 0 4px;
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
}

.image-container img {
  opacity: 0.8;
  height: 450px;
  border-radius: 15px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
}

/* Create two equal columns that sits next to each other */
.column {
  -ms-flex: 50%;
  flex: 50%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  border-radius: 15px;
}

.gallery-img {
  border-radius: 15px;
  object-fit: fill;
}

.gallery-image-warper {
  display: flex;
  gap: 1rem;
}

.gallery-img-right {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}

.single-image {
  position: relative;
  z-index: 2;
}

.single-image::after {
  content: "";
  position: absolute;
  width: 320px;
  height: 320px;
  background-color: #f1f9ec;
  z-index: -1;
  border-radius: 75%;
  bottom: 500%;
  left: 51%;
}

.both-side-content {
  display: flex;
  flex-direction: row;
}

.both-side-content-header-section {
  width: 50%;
}

.both-side-content-para-section {
  padding-top: 1rem;
  width: 50%;
  color: #3d3e46;
}

.both-side-content-header {
  font-size: 2rem;
  line-height: 3rem;
}

.both-side-content-para {
  width: 80%;
  line-height: 24px !important;
  color: #3d3e46;
}

.galleryPageFooterPadding {
  padding: 10rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.galleryPageFooterPadding span {
  z-index: 1;
  font-family: "Inter", sans-serif;
  width: 80%;
}

.galleryPageFooterPadding span::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  background-color: #f1f9ec;
  z-index: -1 !important;
  border-radius: 75%;
  top: 16%;
  left: 42%;
}

@media (max-width: 1270px) {
  .single-image::after {
    display: none;
  }

  .content-warper {
    flex-direction: column-reverse;
  }

  .content-text-side,
  .content-image-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .gallery-content-para {
    width: 100%;
  }

  .gallery-image-warper {
    gap: 0.5rem;
    justify-content: center;
  }

  .gallery-header {
    font-size: 2rem;
  }
}

@media (max-width: 890px) {
  .gallery-header-section * {
    width: 74%;
  }

  .galleryPageFooterPadding span::after {
    left: 34%;
  }
}

@media (max-width: 650px) {
  .galleryPageFooterPadding span::after {
    left: 21%;
  }

  .gallery-header {
    font-size: 1.6rem;
    line-height: 45px;
  }

  .gallery-header-section * {
    width: 84%;
  }

  .both-side-content {
    flex-direction: column;
  }

  .both-side-content-para-section,
  .both-side-content-header-section {
    width: 100%;
  }

  .single-image::after {
    display: none;
  }

  .gallery-img {
    max-width: 260px !important;
  }

  .gallery-content-header {
    line-height: 30px;
  }

  .gallery-content-padding {
    padding: 5%;
  }

  .gallery-header-para,
  .gallery-content-para {
    text-align: start;
  }
}

@media (max-width: 530px) {
  .galleryPageFooterPadding span::after {
    left: 21%;
  }

  .gallery-header-section * {
    width: 94%;
  }

  .gallery-header {
    font-size: 28px;
    line-height: 38px;
  }

  .both-side-content-header {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .both-side-content-para {
    width: 100%;
  }

  .gallery-img {
    max-width: 180px !important;
  }
}

@media (max-width: 450px) {
  .galleryPageFooterPadding span::after {
    width: 260px;
    height: 260px;
    top: 25%;
    left: 18%;
  }

  .gallery-img {
    max-width: 130px !important;
  }

  .galleryPageFooterPadding span {
    width: 99%;
  }
}
