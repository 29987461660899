.historyContainer {
  position: relative;
}
.historyCard:hover {
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); */
}
.historyHeader {
  font: normal normal bold 1.7em Open Sans;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.5em;
}
.mainHeading {
  margin-bottom: 0.75em;
}
.historyCard {
  margin: auto;
  /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%); */
  transition: 0.3s;
  width: 90%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background: transparent;
}
.bookingHistoryGrid {
  /* display: grid;
  grid-gap: 3.5em;
  grid-template-columns: repeat(3, 1fr); */
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
}
@media (min-width: 500px) {
  .historyCard {
  }
}
