.car-wash-heading {
  text-align: center;
  font-size: 2.3em;
  font-weight: bold;
  /* padding-bottom: 1em; */
}
.car-wash-container {
  padding: 2.5em 0;
}
.car-wash-content {
  padding: 1.5em 9em;
  text-align: center;
  font-size: 1.2em;
  letter-spacing: 0.98px;
  font-family: "Open Sans";
}

@media (max-width: 1050px) and (min-width: 700px) {
  .car-wash-content {
    padding: 0.5em 3em;
  }
}
@media (max-width: 700px) {
  .car-wash-content {
    padding: 0.5em 1em;
  }
}
@media (min-width: 1500px) {
  .car-wash-container {
    margin: auto;
    width: 90em;
  }
  .car-wash-content {
    padding: 1.5em 0em;
  }
}
