.pricingContainer {
  position: relative;
}
.pricing-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.pricingHeader {
  font: normal normal bold 1.7em Open Sans;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}
.monthly img{
  position: absolute;
  top: -35px;
  right: 17px;
  width: 5em;
}

.pricingCard {
  margin: auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 70%;
  border-radius: 10px;
  display: flex;
  background: white;
}

.pricing-input {
  font: normal normal normal 16px Open Sans;
  width: 70%;
  height: 32px;
  background: #fff;
  margin: 20px auto;
  padding: 11px;
  border: 1px solid #c5c5c5;
  outline: none;
  border-radius: 12px;
}

.headSection,
.tableSection {
  width: 100%;
}
.headSection {
  justify-content: flex-end;
}
.headSection,
.columns {
  display: flex;
}
.columns {
  align-items: flex-end;
}
.th {
  width: 9em;
  font-size: 8px;
  padding-left: 1em;
  padding-right: 1em;
  position: relative;
}
.mostpopular{
  position: absolute;
  font-size: 0.8em;
  background: linear-gradient(to bottom, #0791BF, #11D545);
  color: white;
  padding:0.8em 2.4em;
  top: -72px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;    
}

.tdCenter {
  text-align: center;
  border-bottom: 1px solid #DBDBDB;
}
.mainTheader {
  text-align: left;
  color: #04A8E8;
  font-size: 1.3em;
  padding: .3em;
  font-weight: bold;
}

.headingRow{
 background: #EFEFEF;
 
}
.tdServiceName {
  font-size: 10px;
  padding: .25em 0em;
  border-bottom: 1px solid #DBDBDB;
}
.tableGap {
  height: 2em;
}
.tableGapSmall {
  height: 1em;
}


@media (min-width: 500px) {
  .pricingCard {
    padding: 2em;
  }
  .th,
  .tdServiceName {
    font-size: 1em;
  }


}

@media (max-width: 700px) {
.pricingCard {
    width: 75%;
    overflow-x: scroll;
  }
  .mainTheader { 
    position: sticky;
    left: 0;
    z-index: 1;
    opacity: 1;
    background-color: white;
    padding-left: 1em;
    border-right: 1px solid grey;
    padding-right: 1em;
  }

  .tdServiceName {
    position: sticky;
    left: 0;
    opacity: 1;
    background-color: white;
    padding-left: 2em;
    padding-right: 1em;
    border-right: 1px solid grey;
  }
  .th{
    padding-top:1em;
  }
  .monthly img {
    top: -2px;
    right: 9px;
    width: 6em;  
  }
}