.steam-benefits-heading {
  text-align: center;
  font-size: 2.3em;
  font-weight: bold;
  padding-bottom: 1em;
}
.steam-benefits-container {
  padding-bottom: 2.5em;
}
.steam-benefits-image-wrapper {
  width: 10em;
  text-align: center;
}
.steam-benefits-image-wrapper img {
  width: 6em;
  height: 6em;
}

.steam-benefits-content {
  margin: auto;
  width: 44em;
  text-align: center;
  font-size: 1.2em;
  letter-spacing: 0.98px;
  font-family: "Open Sans";
}
.steam-benefits-images {
  display: flex;
  justify-content: space-around;
  padding: 2em;
  margin: auto;
  width: 61em;
}
.steam-benefits-image {
  padding-bottom: 1em;
}
@media (max-width: 1215px) {
  .steam-benefits-images {
    width: 85%;
  }
}

@media (max-width: 948px) {
  .steam-benefits-content {
    padding: 0.5em 1em;
    width: 92%;
  }
  .steam-benefits-image {
    text-align: center;
  }
  .steam-benefits-images {
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
  }
  .steam-benefits-image-content {
    text-align: center;
  }
  .steam-benefits-image-wrapper {
    padding-bottom: 1em;
  }
}
@media (min-width: 1500px) {
  .steam-benefits-container {
    margin: auto;
    width: 90em;
  }
}
