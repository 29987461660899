.addPaymentProfile-background {
  z-index: -1;
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
}
.addPaymentProfile-container {
  position: relative;
}
.addPaymentProfile-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.addPaymentProfile-gradient-base {
  position: relative;
}
.addPaymentProfile-gradient {
  position: relative;
  height: 23em;
}

.addPaymentProfile-header {
  font: normal normal bold 1.4em Open Sans;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 1em;
}
/* .addPaymentProfile-placer {
  top: 8em;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
} */
.addPaymentProfile-card {
  margin: auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 86%;
  height: auto;
  border-radius: 10px;
  background: white;
  padding: 1em 0;
}
.addPaymentProfile-margin {
  margin-top: 42em;
}
.addPaymentProfile-label {
  text-align: left;
  padding-left: 2em;
}
.input-payment {
  font: normal normal normal 16px Open Sans;
  width: 80%;
  height: 32px;
  background: #fff;
  margin: 20px auto;
  padding: 11px;
  border: 1px solid #c5c5c5;
  outline: none;
  border-radius: 12px;
}

.addPaymentProfile-divider {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.addPaymentProfile-label {
  padding-left: 1.2em;
  font-weight: bold;
  font-size: 17px;
  color: #17b3f0;
}
.addPaymentProfile-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3em;
}
.addPaymentProfile-sumbit-section {
  width: 9em;
  color: #ffffff;
  font-weight: bold;
  padding-left: 6em;
}
@media (min-width: 490px) {
  .addPaymentProfile-card {
    width: 26em;
    height: auto;
  }
}
@media (min-width: 800px) {
  .addPaymentProfile-card {
    width: 49em;
    height: auto;
  }
  .addPaymentProfile-submit {
    justify-content: flex-end;
  }
  .addPaymentProfile-margin {
    margin-top: 19em;
  }
  .addPaymentProfile-divider {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    align-self: center;
    background: white;
  }
  .addPaymentProfile-placer {
    margin: auto;
    width: 49em;
  }
}
