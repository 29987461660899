.customerReview-header {
  font: normal normal bold 1.8em Open Sans;
  color: #ffffff;
  text-align: center;
  padding-bottom: 2em;
}
.customerReview-card {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  height: auto;
  border-radius: 10px;
  background: white;
  width: 25em;
  margin-bottom: 2em;
}
.customerReview-cardconatiner {
  padding: 1.5em;
}
.customerReview-card-section {
  padding: 1em 0em;
  color: #1ea2c4;
  font-size: 1.2em;
  font-weight: bold;
}
.customerReview-placer {
  padding: 2em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.customerReview-head {
  display: flex;
}
.customerReview-card-header {
  padding: 1em;
}
