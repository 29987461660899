.vehicleCollection-background {
  z-index: -1;
  top: 0;
  height: 100%;
  object-fit: cover;
}
.vehicleCollection-tick {
  position: absolute;
  top: -2em;
  left: -1em;
}
.vehicleCollection-tick img {
  width: 4em;
  height: 4em;
}
.vehicleCollection-dot {
  position: absolute;
  top: 0em;
  right: 1em;
}
.vehicleCollection-dot img {
  width: 2em;
}
.vehicleCollection-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.vehicleCollection-gradient-base {
  position: relative;
}
.vehicleCollection-gradient {
  position: relative;
  height: 48em;
}
.vehicleCollection-margin {
  height: 23em;
  margin-top: 30em;
}

.vehicleCollection-header {
  font: normal normal bold 1.4em Open Sans;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 2em;
}
.vehicleCollection-placer {
  top: 21em;
  left: 0;
  bottom: 0;
  right: 0;
}
.vehicleCollection-card {
  position: relative;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 91%;
  height: auto;
  border-radius: 10px;
  background: white;
  margin-bottom: 4em;
}
.vehicleCollection-detail {
  padding-left: 2em;
  padding-top: 1em;
}
.vehicleCollection-vehicle {
  display: flex;
}
.vehicleCollection-detail-header {
  font-size: 1.5em;
  text-align: center;
  padding: 0.5em 0;
  font-weight: bold;
  color: #04a8e7;
}
.vehicleCollection-carlogo {
  padding: 0.5em 2em;
}
.vehicleCollection-carlogo img {
  width: 6em;
  height: 8em;
}
.vehicleCollection-card-section {
  padding-left: 2em;
  padding-top: 2em;
}
.vehicleCollection-card-details {
  padding-bottom: 0.8em;
  font-weight: bold;
}
.vehicleCollection-divider-card {
  display: grid;
}

.vehicleCollection-label {
  padding-left: 1.2em;
  font-weight: bold;
  font-size: 17px;
  color: #17b3f0;
}
.vehicleCollection-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3em;
}
.vehicleCollection-sumbit-section {
  width: 9em;
  color: #ffffff;
  font-weight: bold;
  padding-left: 6em;
}
@media (min-width: 490px) {
  .vehicleCollection-card {
    width: 30em;
  }
  .vehicleCollection-carlogo img {
    width: 8em;
    height: 8em;
  }
}
@media (min-width: 1012px) {
  .vehicleCollection-divider-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .vehicleCollection-margin {
    margin-top: 6em;
  }
}
@media (min-width: 1100px) {
  .vehicleCollection-divider-card {
    width: 68em;
    margin: auto;
  }
  .vehicleCollection-submit {
    width: 64em;
    margin: auto;
    justify-content: flex-end;
  }
}
