.section-container {
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
}
.section-iconholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 20em;
}
.subsection-para {
  text-align: left;
}
.section-provider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 6em;
  margin-right: 6em;
  padding-bottom: 4em;
}
.section-header {
  text-align: center;
  font-size: 2em;
  color: #20404a;
  font-weight: 900;
  padding-top: 1.6em;
  margin-bottom: 2em;
}
.image-layout img {
  height: 14em;
}
.subsection-layout {
  font-size: 1.3em;
  font-weight: 700;
  padding: 0.8em;
}

@media (min-width: 932px) and (max-width: 1092px) {
  .section-provider {
    margin-left: 3em;
    margin-right: 3em;
  }
}
@media (min-width: 700px) and (max-width: 932px) {
  .section-provider {
    margin-left: 1em;
    margin-right: 1em;
  }
}
@media (max-width: 700px) {
  .section-provider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 12em;
    margin-right: 12em;
    /* margin-bottom: 4em; */
  }
  .section-iconholder {
    padding-bottom: 3em;
    width: 24em;
  }
  .subsection-para {
    padding: 0em 1em;
  }
}
@media (min-width: 1500px) {
  .section-provider {
    width: 90em;
    margin: auto;
  }
}
