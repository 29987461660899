.geta-app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 2em;
  padding-top: 3em;
}

.get-app-title {
  font-weight: bold;
}
.get-app-heading {
  font-size: 1.7em;
}
.get-app-para {
  padding-bottom: 2em;
  font-size: 0.9em;
  padding: 2em 1em;
}
.get-app-email {
  justify-content: center;
  align-items: center;
}
/* .get-app-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 20px;
  opacity: 1;
  width: 17em;
  height: 3em;
} */
.get-app-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 4px 2px;
  border-radius: 8px;
  padding: 20px;
  width: 21em;
}

.get-app-buttoncontainer {
  display: flex;
  justify-content: space-around;
}

.get-email-button {
  background-color: #058fbc;
  border: none;
  color: white;

  text-align: center;
  text-decoration: none;
  display: inline-block;

  margin: 4px 2px;
  border-radius: 8px;

  padding: 14px;
  font-size: 19px;
  width: 17em;
}
.get-app-padding {
  padding-top: 0.5em;
}
.button-wrapper {
  width: 10em;
  padding-left: 1em;
}

.get-head-padding {
  padding: 2em;
}

@media (min-width: 980px) {
  .geta-app-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .get-app-wrapper {
    flex: 1;
  }
  .get-app-image {
    flex: 1;
  }
  .get-app-wrapper {
    text-align: left;

    font-size: 1.4em;
  }
  .get-app-buttoncontainer {
    justify-content: flex-start;
  }
  .get-app-email {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .get-head-padding {
    padding-left: 0em;
  }
  .button-wrapper-pad {
    padding-left: 0em;
  }
  .get-app-input {
    width: 24em;
    height: 1.5em;
    border-radius: 20px;
  }
  .get-app-padding {
    padding-left: 0.5em;
    padding-top: 0em;
  }

  .get-email-button {
    border-radius: 20px;
    font-size: 19px;
    width: 11em;
    padding: 20px;
  }
  .get-app-para {
    padding-left: 0em;
    padding-right: 8.2em;
  }
  .button-wrapper-pad {
    padding-left: 1.5em;
  }
}
/* @media (max-width: 400px) {
  .get-app-input {
    width: 21em;
  }
  .get-app-padding {
    padding-left: 0em;
  }
  .get-email-button {
    padding: 14px;
    font-size: 19px;
    width: 17em;
  }
} */

/* @media (max-width: 320px) {
  .get-app-input {
    width: 10em;
  }
  .get-app-padding {
    padding-left: 0em;
  }
  .get-email-button {
    font-size: 12px;
    width: 8em;
    padding: 16px;
  }
} */
