.pricing-packages-bodytype-header {
  padding: 1em;
}
.pricing-packages-container {
  margin: 4em 0em;
}
.pricing-packages-imagesection {
  display: flex;
}
.pricing-packages-carbody {
  width: 8em;
  display: flex;
  flex-direction: column;
}
.pricing-packages {
  display: flex;
  display: flex;
  column-gap: 3em;
  justify-content: center;
}
.addVehicleborder {
  border: 3px solid #03a6ed;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 30%);
}
.react-multi-carousel-item {
  width: 8em;
}
.plan-heading {
  text-align: center;
  font-size: 2.5em;
  padding: 1em;
  font-weight: bold;
}
.pricing-packages-heading {
  text-align: center;
  padding: 3em;
  border-radius: 15px;
  font-weight: 900;
  font-size: 1.3em;
  height: 2em;
}
.pricing-packages-background-gold {
  background-image: linear-gradient(#efc757, #e5c464, #aa8d2e);
}
.pricing-packages-background-platinum {
  background-image: linear-gradient(#b2b1aa, #c9c8bd, #858376);
}

.pricing-packages-background-diamond {
  background-image: linear-gradient(#6bb9f5, #6bb9f5, #6bb9f5);
}

.pricing-packages-exterior {
  font-weight: 900;
  font-size: 1.3em;
  text-align: center;
  padding: 1em;
}
.pricing-packages-includedServices {
  padding: 1em;
}
.pricing-packages-includedService {
  padding: 0.5em 0em;
  display: flex;
}
.packages-tick {
  padding: 0.5em;
}
.packages-tick img {
  width: 1.1em;
}
.includedService-title {
  padding: 0em 0.5em;
}
.additional-card {
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 20rem;
  /* min-width: max-content;
  max-width: fit-content; */
  min-height: 27em;
  height: auto;
  background: white;
  margin-bottom: 2em;
  border: 1px solid #c7c7c7;
}
.addditional-cards {
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: 1fr;
  grid-template-rows: 1fr; */
  text-align: center;
  margin: auto;
  width: 100%;
}

.additional-card-upper {
  padding-bottom: 1em;
}

.additional-card-image {
  padding: 2em 2em 1em;
}
.additional-card-image img {
  width: 8em;
}
.additional-card-content {
  padding: 2em;
}
.additional-card-value {
  padding: 1em;
  font-size: 1.1em;
  font-weight: bold;
  border-top: 2px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 2px solid #c7c7c7;
  border-left: 1px solid #c7c7c7;
  flex: 1;
  display: flex;
  justify-content: center;
  column-gap: 0.5em;
}

.pricing-packages-bodytype {
  padding-bottom: 4em;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px);
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px);
}

.react-multi-carousel-list-pricing {
  margin: 0em 5em;
}

.additional-card-price {
  display: flex;
  justify-content: space-around;
}
.additional-card-header {
  font-size: 1.2em;
  padding: 0em 1.5em;
  font-weight: bold;
  height: 3.5em;
}

.pricing-packages-button {
  text-align: center;
}
.pricing-packages-card {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 15em;
  height: auto;
  border-radius: 15px;
  background: white;
  padding-bottom: 2em;
}

@media (min-width: 880px) {
  .pricing-packages-image {
    display: flex;
    /* justify-content: center; */
    width: 66em;
    overflow: auto;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 4px 6px rgba(0, 0, 0, 0.3);
  }

  .addditional-cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}
