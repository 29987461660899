.sdp {
  --theme-color: #02a1de !important; /* violet color */
  width: auto !important;
}
.sdp--square-btn {
  box-shadow: 0px 3px 6px #00000029;
  color: #02a1de;
}
.sdp--date-btn__selected {
  color: white;
}
.sdp--text__inactive.sdp--square-btn.sdp--date-btn {
  display: none;
}

.sdp--square-btn.sdp--date-btn.sdp--text.sdp--text__inactive {
  /* display: none; */
  opacity: 0.5;
}
.sdp--text {
  font-weight: 900;
}

.sdp--square-btn.sdp--date-btn.sdp--text {
  width: 2em;
  height: 2em;
  font-size: 3em;
  display: flex;
  flex-direction: column;
}
.sdp--dates-grid {
  grid-template-columns: 1fr 1fr 1fr !important;
  height: 13em;
  overflow: auto;
}

.sdp--text.sdp--text__inactive {
  display: none;
  opacity: 1;
}
.weekday-label {
  font-size: 12px;
}
@media (min-width: 736px) {
  .sdp {
    width: 24em !important;
    height: 24em;
  }
  .sdp--text__inactive.sdp--square-btn.sdp--date-btn {
    opacity: 0.5;
    display: block;
  }
  .sdp--text.sdp--text__inactive {
    display: block;
  }

  .sdp--dates-grid {
    grid-template-columns: inherit;
  }
  .sdp--dates-grid {
    grid-template-columns: repeat(7, 1fr) !important;
    height: 90%;
    overflow: auto;
  }

  .sdp--square-btn.sdp--date-btn.sdp--text {
    width: 3em;
    height: 3em;
    font-size: 16px;
  }

  .sdp--square-btn.sdp--date-btn.sdp--text.sdp--text__inactive {
    width: 3em;
    height: 3em;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .weekday-label {
    display: none;
  }
}
