.kivlik-container {
  width: 100%;
  height: 100%;
}
.kivlik-main-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
.kivlik-wrapper {
  grid-area: 1/1;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.kivliksvg-gradient {
  grid-area: 1/1;
}
.kivlik-content {
  padding-top: 5em;
  align-items: center;
}
.kivlik-image {
  text-align: center;
}

.kivlik-tickcontainer {
  padding-left: 3em;
  font-size: 0.8em;
}
.kivlik-subcontent-para {
  font-size: 0.9em;
  font-weight: normal;
  font-family: "Open Sans";
}

.kivlik-heading {
  text-align: center;
  font-size: 2.3em;
  font-weight: bold;
  padding-bottom: 0.5em;
}
.kivlik-sub {
  text-align: center;
  font-size: large;
}
.kivlik-tick {
  display: flex;
  justify-content: left;
  align-items: center;
}
.kivlik-subcontent {
  font-size: 1.4em;
  font-weight: 900;
  font-family: "Open Sans";
  padding-left: 1em;
}

@media (max-width: 981px) {
  .kivlik-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .kivlik-image {
    padding-left: 0em;
    text-align: center;
  }
  .kivlik-tick {
    justify-content: left;
  }
  .kivlik-tickcontainer {
    margin-top: 0em;
    padding: 0em 1em;
  }
}
@media (min-width: 1500px) {
  .kivlik-wrapper {
    width: 90em;
    margin: auto;
  }
}
