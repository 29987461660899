.signup-background {
  z-index: -1;
  top: 0;
  height: 100%;
  object-fit: cover;
}

.signup-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.signup-gradient-base {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #0bac912d;

}
.signup-gradient {
  height: 30em;
}
.secondGradient {
  position: relative;
  top: -31em;
  z-index: -1;
  margin-bottom: -31em;
}
.baseCard {
  padding: 0em 0em;
  background: transparent;
  position: relative;
  width: 100%;
  top: -17em;
}
@media (min-width: 1000px) {
  .signup-divider {
    display: flex;
    flex-direction: row;
  }
  .signup-card {
    width: 58em;
    height: 31em;
  }
  .signup-upload-section {
    width: 24em;
    padding-left: 1em;
  }
  .signup-margin {
    /* margin-top: 15em; */
  }
}
@media (min-width: 500px) and (max-width: 1000px) {
  .signup-card {
    width: 27em;
    height: 54em;
  }
  .signup-divider {
    display: flex;
    flex-direction: column;
  }
}
