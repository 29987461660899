.termsPadding {
    padding: 1rem 10%;
}

.termsHeader h1 {
    color: #0D0C22;
    font-size: 3rem;
    line-height: 1.2;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.termsHeaderText {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.termsHeaderText p {
    margin-top: .5rem;
    color: #69B4F2;
    font-size: 1.1rem;
}

.termsHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.termsPara {
    line-height: 2.2;
}

.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floating {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 15px);
    }

    to {
        transform: translate(0, -0px);
    }
}

@media (max-width : 800px) {
    .termsHeader {
        flex-direction: column-reverse;
    }

    .termsHeaderText {
        text-align: center;
        justify-content: center;
        width: 100%;
    }

    .termsPadding {
        padding: 1rem 5%;
    }

}