.contact-background {
  z-index: -1;
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
}
.contact-card-image img {
  width: 11em;
  position: absolute;
  top: -8em;
  left: 2em;
}
.contact-card-image {
  padding-bottom: 7em;
}
.contact-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.contact-gradient-base {
  position: relative;
}
.contact-gradient {
  position: relative;
  height: 23em;
}
.contact-card-section {
  font-size: 1.1em;
  font-weight: bold;
}

.conatact-card-header {
  font-size: 1.8em;
  font-weight: bold;
  padding-top: 0.5em;
  color: #ffffff;
}
.contact-card-para {
  text-align: center;
  padding: 2em 5em;
}
.contact-card-para-head {
  font-size: 1.3em;
  color: white;
  font-weight: bold;
  padding-bottom: 0.5em;
}
.contact-card-parasection {
  font-size: 0.7em;
  padding: 0 6em;
}

.contact-header {
  font: normal normal bold 1.8em Open Sans;
  color: #ffffff;
  text-align: center;
  padding-bottom: 8em;
}
textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}

.contact-card-layout {
  display: flex;
  padding: 3.8em 1em;
  align-items: center;
  justify-content: flex-start;
}
.contact-card-layout:nth-child(even) {
  background-color: #f1f1f1;
}
.contact-card-image {
  display: flex;
  align-items: center;
  flex: 1;
}

.contact-card-image img {
  padding: 0em 0.5em;
}
.contact-card-content {
  flex: 1;
  padding-left: 2em;
}
.contact-card {
  position: relative;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 91%;
  height: auto;
  border-radius: 10px;
  background-image: linear-gradient(#05abe4, #15c8b9);
  margin-bottom: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contact-card2 {
  position: relative;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 91%;
  height: auto;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 3.5em;
}
.contact-input {
  font: normal normal normal 16px Open Sans;
  width: 96%;
  height: 32px;
  background: #fff;
  margin: 20px auto;
  padding: 11px;
  border: 1px solid #c5c5c5;
  outline: none;
  border-radius: 12px;
}

.contact-card2-input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-vehicle {
  display: flex;
  padding-left: 1em;
  justify-content: center;
}

.contact-card-packageimage {
  padding: 2em 1em;
  padding-bottom: 1em;
}
.contact-card-packageimage img {
  width: 1.5em;
}
.contact-card-subsection {
  padding-top: 1em;
}
.contact-card-subsection img {
  width: 5em;
}
.contact-headercard2 {
  font-size: 1.8em;
  padding: 0.5em;
  padding-left: 0;
  font-weight: bold;
  color: #06ace3;
}
.contact-card-time {
  display: flex;
  align-items: center;
  padding-left: 1em;
  color: #03a6ea;
}
.contact-us-options {
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: #ffffff;
  color: #03577b;
  padding: 0.75rem 3rem;
  border-radius: 57px;
  font-weight: bold;
  width: 18em;
  margin-bottom: 1.5em;
  display: flex;
}
.contact-us-block {
  padding-bottom: 2em;
}
.contact-us-option {
  padding-right: 1em;
}
.contact-us-option img {
  width: 3em;
}
.contact-submit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-sumbit-section {
  width: 9em;
  color: #ffffff;
  font-weight: bold;
  padding-left: 6em;
  cursor: pointer;
  max-width: 15em;
}
@media (min-width: 715px) {
  .contact-card {
    width: 34em;
  }
  .contact-card-header {
    font-weight: bold;
    padding-top: 0.5em;
    font-size: 1.3em;
  }
  .contact-card-image {
    font-size: 1.4em;
  }
  .contact-card-content {
    font-size: 1.6em;
  }
  .contact-card-value {
    padding: 0.5em;
    color: #747778;
    font-size: 1.2em;
    display: flex;
    justify-content: space-between;
  }
  .contact-headercard {
    width: 37%;
    font-size: 1.5em;
  }
}

@media (min-width: 1364px) {
  .contact-divider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 83em;
    margin: auto;
  }
  .contact-header {
    padding-bottom: 2em;
  }
  .contact-submit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 83em;
    margin: auto;
  }
}
