input {
  font: normal normal normal 16px Open Sans;
  width: 97.5%;
  height: 32px;
  background: #fff;
  padding: 11px;
  border: 1px solid #c5c5c5;
  outline: none;
}
.pac-container {
  width: -moz-fit-content;
  background-color: #fff;
  position: absolute !important;
  z-index: 4;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.pac-item {
  width: 100%;
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 35px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 11px;
}

.addYourLocation-input1 {
  width: 98%;
  border-radius: 10px;
}

.pac-item:hover {
  background-color: #6666;
}
.pac-item-selected,
.pac-item-selected:hover {
  background-color: #6666;
}
.pac-item-query {
  font-size: 13px;
  padding-right: 3px;
  color: #000;
}

.pac-icon {
  width: 15px;
  height: 20px;
  margin-left: 0.5em;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
}

.hdpi .pac-icon {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
}

.search-wrapper {
  display: flex;
  flex-direction: row;
}

@media (max-width: 880px) {
  .addYourLocation-input1 {
    width: 90%;
  }
}
