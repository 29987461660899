
.confirmation-main-content {
    color: #ffffff;
    padding-top: 6em;
}

.span-heading {
    font-weight: bold;
}

.confirmation-main-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}
.confirmation-detail-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}

.bg-confirmation {
    grid-area: 1/1;
}

.confirmation-wrapper {
    padding: 2em 2em;
    grid-area: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.confirmation-detail-content{
    color:#4B4B4B;
    padding:0 4em 6em; 
    width: 95%;
    margin: auto;
    font-weight: bold;
}

.confirmation-detail-heading {
    font-size: 1.3em;
}

.confirmation-details{
    display: flex;
    justify-content: space-between;
    padding: .7em;

}

.confirmation-content {
    margin: 0 2em;
    display: flex;
    max-width: 42em;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;
}
.location-container{
    padding: 2em;
}

.confirmation-heading {
    font-size: 2em;
}
.confirmation-contact{
   display: flex;
}
.confirmation-contact-details{
    display: flex;
    align-items: center;
    padding: 1em;
    flex:1;
}

@media (max-width: 600px) {
    .confirmation-detail-wrapper {
    grid-template-columns: 1fr;
    }
    .confirmation-detail-content {
        padding: 0px 2em 2em;
    }
}

@media (min-width: 1200px) {
    .confirmation-wrapper {
        text-align: unset;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .confirmation-content {
        max-width: 565px;
        padding-left: 3em;
    }

    .confirmation-heading {
        font-size: 2.5em;
    }

    .confirmation-para {
        font-size: 1.3em;
        padding: 0.5em 0;
    }

    .confirmation-image {
        max-width: 908px;
    }

    .bg-image {
        max-height: 650px;
    }
}

@media (min-width: 1500px) {
    .confirmation-wrapper {
        width: 90em;
        margin: auto;
    }
    .confirmation-main-content {
        padding-top: 3em;
    }
}