.SaveYourTime-container {
  background-color: #03a8e6;
}
.SaveYourTime-placer {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
}

.SaveYourTime-image img {
  width: 29em;
}

.SaveYourTime-content-section {
  padding: 4em;
}

.SaveYourTime-content-heading {
  font-size: 2em;
  font-weight: bold;
  color: #fcfeff;
}

.SaveYourTime-content {
  font-size: 1.1em;
  padding-top: 1em;
  color: #fffcfc;
}

@media (min-width: 970px) {
  .SaveYourTime-placer {
    flex-direction: row;
    width: 85%;
  }
}

@media (min-width: 1460px) {
  .SaveYourTime-placer {
    width: 75em;
  }
  .SaveYourTime-content-section {
    padding: 8em;
  }
}
