@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
/* * {
  box-sizing: border-box;
}
body {
  margin: 0;
}  */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.actionHeader {
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
  padding: 1em;
}

.error {
  color: #d8000c;
  background-color: #ffbaba;
  background-image: url("https://i.imgur.com/GnyDvKN.png");
  border: 1px solid;
  margin: 10px 0px;
  padding: 15px 10px 15px 50px;
  background-repeat: no-repeat;
  background-position: 10px center;
}

/* .content-shadow {
  text-shadow: 0px 1px 2px black;
} */
.back-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5em;
  column-gap: 4em;
}
@media (min-width: 1100px) {
  .back-submit {
    width: 64em;
    margin: auto;
    justify-content: space-between;
  }
}

a:hover {
  text-decoration: underline;
}

/* login/signup logo */
.login-logo img {
  object-fit: cover;
  width: 78px;
  padding-top: 1em;
}

.auth-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3em;
}
.auth-sumbit-section {
  width: 9em;
  color: #ffffff;
  font-weight: bold;
  padding-left: 6em;
  cursor: pointer;
  max-width: 15em;
}
