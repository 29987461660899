.menuUser-dropdown {
  position: absolute;
  top: 6rem;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  background-color: white;
}
.menuUser-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 0.3em;
  width: 9.5em;
}
.menuUser-content {
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.menuUser-content:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
