.privacy_page_layout {
	padding: 2% 3%;
	background-color: rgb(255, 255, 255);
	display: flex;
	margin-top: -110px !important;
	height: 80vh;
	justify-content: space-evenly;
	scroll-padding: 100rem;
	position: sticky;

}

.privacy_page_map {
	width: 20%;
	padding: 3rem;
}

.privacy_map_header {
	font-size: 14px;
	font-weight: 600;
	padding: 0 1rem;
	cursor: pointer;
	margin: 10px 0px;
	color: rgb(98, 98, 98);
	transition-duration: 500ms;
}

.privacy_map_header:hover {
	color: rgb(0, 0, 0);
	transform: scale(1.01)
}

.privacy_page_content {
	width: 80%;
	padding: 3rem;
}

.privacy_page_content_section_warper {
	overflow-y: scroll;
	height: 90%;
	scroll-behavior: smooth;
	scroll-padding-top: 10rem;
	scroll-margin: 10rem;

}

.privacy_page_content_section {
	padding: 1rem .5rem;
}

.privacy_page_content_section h2 {
	font-weight: 900;
	color: rgb(21, 21, 21);
}

.privacy_page_content_section p {
	font-size: 14px;
}