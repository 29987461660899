.bookingCard {
  width: 350px;
  min-height: 150px;
  height: auto;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 1em;
}
.mainArea,
.content,
.paymentStatus,
.actionArea,
.statusIcon {
  display: flex;
}
.mainArea {
  flex-direction: column;
  border-bottom: 1px dashed #c5c5c5;
  gap: 0.5em;
  padding-bottom: 34px;
  margin-bottom: 16px;
}
.cancelDropdown {
  position: absolute;
  top: -15px;
  right: -17px;
  z-index: 4;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}
.cancellist {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 1.6em;
}

.cancel-content {
  padding: 8px 12px;
  width: 3em;
}

.cancel-content:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.dotplacer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.dot img {
  width: 2em;
  padding-bottom: 0.5em;
}
.date {
  text-align: left;
  font: normal normal 600 15px/20px Open Sans;
  letter-spacing: 0.59px;
  color: #04a8ea;
}
.packageName {
  text-align: left;
  font: normal normal 600 17px/23px Open Sans;
  letter-spacing: 0.59px;
  color: #000000;
}
.bookingId {
  text-align: left;
  font: normal normal normal 15px/20px Open Sans;
  letter-spacing: 0.53px;
  color: #757373;
}
.content {
  justify-content: space-between;
}
.amount {
  text-align: left;
  font: normal normal bold 17px/24px Open Sans;
  letter-spacing: 0.84px;
  color: #000000;
}
.actionArea {
  justify-content: space-between;
}
.notPaid {
  color: #fc0d0d;
}
.paid {
  color: #73c16f;
}

.paid,
.notPaid {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
}
.statusIcon {
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
}
.completedStatus {
  color: #73c16f;
}
.upcomingStatus {
  color: #db9316;
}
.cancelledStatus {
  color: #fc2121;
}
