.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 20vh;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}
.modalsection {
  display: flex;
  justify-content: space-around;
  margin: 2em 0em;
}
.modal-card-textarea {
  display: flex;
  justify-content: center;
  width: 85%;
  margin: auto;
  flex-direction: column;
  padding-bottom: 1em;
}
.modalstar {
  display: flex;
  flex-direction: column;
}
.star {
  padding: 0.5em 0em;
}
.star img {
  width: 1.3em;
  padding: 0em 0.2em;
}
.modal-card-textarea-head {
  font-weight: bold;
  padding: 0.5em;
}
.modal-head {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
