.header {
  position: absolute;
  min-width: fit-content;
  width: 100%;
  background-color: #02a4ef;
  transition: 0.3s;
  padding-bottom: 1em;
  z-index: 5;
}

.opacity-class {
  opacity: 0.9;
  transition: opacity 2s;
}
.menu-item-display {
  display: flex !important;
}
.menu-items.middle div {
  display: none;
  padding: 1em;
  border-bottom: 1px solid lightgray;
  font-weight: bold;
}
.toggle-button {
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 34px;
  height: 25px;
}
.toggle-button .bar {
  height: 4px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}
.navbar {
  display: grid;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  margin: 0em 2em;
}
.main-title {
  display: flex;
  justify-content: left;
  align-items: center;
}
.borderStyle {
  border: 1px solid white;
  padding: 0.5em;
  border-radius: 13px;
  color: #02a4ef;
}

.loginStyle {
  flex: 1;
  border-right: 2px solid white;
  padding-right: 0.5em;
}

.loginStyle:hover {
  text-underline-position: under;
}

.borderStyle:hover {
  background-color: #ffffff;
}

.borderStyle:hover a {
  color: #02a4ef;
  text-decoration: none;
}
.signupStyle {
  flex: 1;
  padding-left: 1em;
}
.signupStyle > button:hover {
  background-color: white;
  color: #02a4ef;
}

.title-logo img {
  object-fit: cover;
  width: 110px;
}
.menu-items.end1 {
  width: 9.5em;
}
.menu-items.end {
  width: 5.5em;
}
.logout-button img {
  width: 3em;
}
.username {
  font-size: 1em;
  font-weight: bold;
  color: #ffffff;
}
.nav-arrow {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
}
.downside {
  bottom: 0;
  border-left: 5px solid white;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

a {
  text-decoration: none;
  color: #ffffff;
}
.menu-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
  text-underline-position: under;
  cursor: pointer;
}
.menu-items.middle {
  grid-area: 2 / 1 / span 1 / span 3;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}
@media (min-width: 1175px) {
  .navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0px 110px;
  }
  .menu-items.middle {
    /* width: 22em; */
    display: flex;
    flex-direction: row;
  }
  .main-title {
    padding-left: 0em;
  }

  .logout-button img {
    width: 2em;
  }

  .toggle-button {
    display: none;
    flex-direction: column;
  }
  .menu-items.middle div {
    display: revert;
    padding: 0em;
    border-bottom: none;
    font-weight: none;
  }
}

@media (max-width: 500px) {
  .title-logo img {
    width: 120px;
  }
  .menu-items {
    font-size: 0.9em;
  }
}

@media (min-width: 1500px) {
  .navbar {
    margin: auto;
    width: 90em;
  }
}

.copyText-tooltip {
  position: relative;
  display: inline-block;
}

.copyText-tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.copyText-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.copyText-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
