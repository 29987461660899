.tabs {
  display: flex;
  /* grid-template-columns: repeat(2, 1fr); */
  justify-content: center;
  grid-gap: 1em;
  flex-direction: column;
  align-items: center;
}
.tabItem {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
  border-radius: 50px;
  text-transform: none;
  cursor: pointer;
  width: fit-content;
}
.active {
  background: white;
  color: #04a9e6;
}
.inActive {
  background: #107ea4 0% 0% no-repeat padding-box;
  color: white;
}

@media (min-width: 500px) {
  .tabs {
    display: flex;
    flex-direction: row;
  }
  .tabItem {
  }
}
