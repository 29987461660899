.section2-container {
  padding-bottom: 2em;
}

.section1-iconholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 18em;
  height: 18em;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50px;
  margin-bottom: 1em;
}
.react-multi-carousel-item {
  display: flex;
  justify-content: center;
}
.section2-header {
  text-align: center;
  font-size: 2em;
  color: #20404a;
  font-weight: bold;
  margin-top: 0.6em;
  margin-bottom: 1.5em;
}
.image1-layout img {
  width: 14em;
  height: 14em;
}
.subsection2-layout {
  padding-top: 2em;
  font-size: 1.2em;
  font-weight: bold;
}

@media (min-width: 415px) and (max-width: 510px) {
  .section1-iconholder {
    margin-bottom: 2em;
  }
}
@media (min-width: 510px) and (max-width: 700px) {
  .section1-iconholder {
    margin-bottom: 2em;
  }
}

@media (max-width: 415px) {
  .react-multi-carousel-list {
    padding-left: 0em;
  }
}
@media (min-width: 1500px) {
  .section2-contain {
    width: 90em;
    margin: auto;
  }
}
