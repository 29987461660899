.about-landingpage-image {
  width: 72%;
}
.about-main-content {
  color: #ffffff;
  padding-top: 5em;
}
.about-span-heading {
  font-weight: bold;
}
.about-image-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
.about-bg-image {
  grid-area: 1/1;
}
.about-landingpage-wrapper {
  padding: 2em 2em;
  grid-area: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.about-landingpage-content {
  margin: 0 2em;
  display: flex;
  max-width: 42em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3em;
}
.about-landingpage-heading {
  font-size: 2em;
  font-weight: bold;
}

@media (min-width: 1200px) {
  .about-landingpage-wrapper {
    text-align: unset;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
  .about-landingpage-content {
    max-width: 565px;
    flex: 1;
    padding-left: 3em;
  }
  .about-landingpage-heading {
    font-size: 2.5em;
  }
  .about-landingpage-para {
    font-size: 1.3em;
  }
  .about-landingpage-image {
    max-width: 908px;
    flex: 1;
  }
  .about-bg-image {
    max-height: 650px;
  }
}
@media (min-width: 1500px) {
  .about-landingpage-wrapper {
    width: 90em;
    margin: auto;
  }
}
