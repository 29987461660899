.common-button {
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: #ffffff;
  color: #03577b;
  padding: 0.75rem 3rem;
  border-radius: 25px;
  font-weight: bold;
  /* max-width: 15em; */
}

.common-button:hover {
  color: #ffffff;
  background-color: #02a4ef;
}

.common-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .common-button{
    padding: 0.7rem 3rem;
  }
}