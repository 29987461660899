.myAccount-background {
  z-index: -1;
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
}
.myAccount-container {
  position: relative;
}
.myAccount-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.myAccount-gradient-base {
  position: relative;
}
.myAccount-gradient {
  position: relative;
  height: 23em;
}
.myAccount-margin {
  margin-top: 60em;
}
.myAccount-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3em;
}
.myAccount-header {
  font: normal normal bold 1.4em Open Sans;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 2em;
}

.myAccount-card {
  margin: auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 91%;
  height: 16em;
  border-radius: 10px;
  background: white;
  margin-bottom: 4em;
  cursor: pointer;
}
.myAccount-detail {
  padding-left: 2em;
  padding-top: 1em;
}
.myAccount-vehicle {
  display: flex;
}
.myAccount-detail-header {
  font-size: 1.5em;
  text-align: center;
  padding: 0.5em 0;
  font-weight: bold;
  color: #04a8e7;
}
.myAccount-carlogo img {
  width: 8em;
  height: 8em;
}
.myAccount-card-section {
  padding-left: 2em;
  padding-top: 1em;
}
.myAccount-card-details {
  padding-bottom: 0.5em;
}

.myAccount-label {
  padding-left: 1.2em;
  font-weight: bold;
  font-size: 17px;
  color: #17b3f0;
}
.myAccount-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3em;
}
.span-shadow {
  text-shadow: 0px 0px 1px #000;
}
.myAccount-sumbit-section {
  width: 9em;
  color: #ffffff;
  font-weight: bold;
  padding-left: 6em;
}
@media (min-width: 558px) {
  .myAccount-card {
    width: 32em;
  }
}
@media (max-width: 400px) {
  .myAccount-carlogo img {
    width: 8em;
    height: 8em;
  }
}
@media (min-width: 1150px) {
  .myAccount-divider-card {
    display: flex;
  }
  .myAccount-margin {
    margin-top: 25em;
  }
  /* .myAccount-card2 {
    margin-right: 2em;
  } */
  .myAccount-card1 {
    margin-left: 2em;
  }
  .myAccount-submit {
    justify-content: flex-end;
  }
  .myAccount-placer {
    margin: auto;
    width: 68em;
  }
}
