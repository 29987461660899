.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mainContent {
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.selectedStatus {
  font: normal normal bold 1em Open Sans;
  color: black;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 2em;
}
.actionHeader {
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
  padding: 1em;
}
.header {
  font: normal normal bold 1.4em Open Sans;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 2em;
}
.actionArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  border-radius: 2em;
  padding: 2em 0em;
  align-items: unset;
  gap: 2rem;
}

.description {
  padding: 0 1em;
  border-radius: 25px;
  background: white;
  margin-bottom: 2em;
  width: 80%;
  max-width: 45rem;
}
.description h2 {
  font-size: medium;
  text-align: center;
  font-weight: 900;
  color: #0686b8;
}
.description p {
  font-size: small;
  line-height: 1.6;
}

.calenderHead {
  display: none;
  font-size: 1.2em;
  margin: 1em;
  color: #0686b8;
}
.calender {
  height: fit-content;
}
.timeHead {
  display: none;
  font-size: 1.2em;
  margin: 1em;
  color: #0686b8;
}
.rightContent {
  display: flex;
  flex-direction: column;
}

.timePicker {
  padding: 0 1em;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  margin-bottom: 2em;
  column-gap: 1em;
}
.slotPicker {
  display: flex;
  flex-direction: row;
  grid-gap: 3em;
  padding: 1em;
  cursor: pointer;
  align-self: flex-start;
  font-weight: bold;
}
.slotActive {
  color: #0686b8;
}
.timeGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.timeStyle {
  color: #17b3f0;
  border-radius: 20px;
  padding: 0.75em;
  margin: 0.75em;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  font-weight: bold;
}
.timeStyleNone {
  color: #17b3f0;
  border-radius: 20px;
  padding: 0.75em;
  margin: 0.75em;
  font-weight: bold;
}
.timeStyleActive {
  background: #17b3f0;
  color: white;
  border-radius: 20px;
}
.value {
  color: #17b3f0;
  font-weight: bold;
}

.timeStyle:hover {
  background: #17b3f0;
  color: white;
  border-radius: 20px;
}
.selectedStatus {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.bookingTime,
.bookingDate {
  display: flex;
  flex-direction: column;
  column-gap: 1em;
}

.title {
  display: flex;
  display: flex;
  align-items: center;
  column-gap: 0.5em;
}

.termsConditionContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.termsConditionContainer input {
  max-width: fit-content;
  /* height: 56px; */
}

@media (min-width: 736px) {
  .actionArea {
    flex-direction: row;
    box-shadow: none;
    border-radius: 0em;
    padding: 0em;
  }
  .calenderHead {
    display: block;
  }
  .timeHead {
    display: block;
  }
  .timePicker {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  }
  .leftContent {
    margin-right: 3em;
  }
}
