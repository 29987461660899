.package-background {
  z-index: -1;
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
}
.package-container {
  position: relative;
}
.package-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.package-gradient-base {
  position: relative;
}
.package-gradient {
  position: relative;
  height: 48em;
}
.package-margin {
  height: 23em;
  margin-top: 62em;
}
.package-tick {
  position: absolute;
  right: -1.5em;
  top: -1.5em;
}

.package-price-main {
  color: white;
  font-weight: bold;
  /* font-size: 1.3em; */
  transform: translateY(-50%);
  position: absolute;
  top: 48%;
  left: 23%;
  /* grid-row: 1 / 2;
  justify-self: center;
  position: absolute;
  top: 4.4em;
  color: white;
  font-weight: bold;
  font-size: 1.3em; */
}

.package-tick img {
  width: 4em;
  height: 4em;
}
.package-header {
  font: normal normal bold 1.4em Open Sans;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 2em;
}

.package-card {
  position: relative;
  /* margin: auto; */
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: white;
  padding: 1rem;

  /* margin-bottom: 4em; */
}
.package-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.package-detail {
  /* padding-left: 2em;
  padding-top: 1em; */
}
.package-vehicle-main {
  display: flex;
  flex-wrap: wrap;
  /* padding-left: 1em; */
  /* justify-content: space-between; */
  /* align-items: center; */
}
.package-detail-header {
  font-size: 1.5em;
  text-align: center;
  /* padding: 0.5em 0; */
  font-weight: bold;
  color: #04a8e7;
}
.package-card-header {
  font-size: 1.2em;
  font-weight: bold;
  /* padding-left: 1em;
  padding-top: 0.5em; */
  color: #03a6ea;
}
.package-card-value {
  /* padding-left: 1em; */
  /* font-size: 1em; */
  font-weight: bold;
  color: #1ea2c4;
}
.package-card-time {
  display: flex;
  align-items: flex-start !important;
  padding: 0.5rem 0;
  /* padding-left: 1em; */
}

.package-card-details {
  /* padding-bottom: 0.8em; */
  font-weight: bold;
}
.package-divider-card {
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}
.package-card-data {
  /* padding-top: 1.5em; */
  /* flex-basis: 23em; */
}

.package-card-section-main {
  /* padding: 1em; */
  padding-bottom: 0em;
  /* display: grid;
  grid-template-rows: auto 1fr; */
  position: relative;
}

.package-card-time {
  /* padding-bottom: 1em; */
  display: flex;
  align-items: center;
  gap: 1rem;
}
.package-label {
  padding-left: 1.2em;
  font-weight: bold;
  font-size: 17px;
  color: #17b3f0;
}

.package-holder {
  /* position: absolute;
  overflow: scroll;
  top: calc(112% - (60% + 30px));
  left: calc(26% - (20px + 30px)); */
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: scroll;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  width: 28rem;
  height: 100vh;
  /* box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); */
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  /* border: 5px solid black; */
}

.package-sumbit-section {
  width: 9em;
  color: #ffffff;
  font-weight: bold;
  padding-left: 6em;
}

.package-card-section-main img {
  width: 4em;
}

.package-card-image {
  padding: 2em 1em;
  padding-bottom: 1em;
  display: none;
}
.package-card-image img {
  width: 2em;
}

@media (min-width: 715px) {
  .package-card {
    max-width: 35em;
    flex-wrap: nowrap;
  }
  .package-card-section-main img {
    width: 5em;
  }
  .package-card-image {
    display: block;
  }
}

@media (min-width: 1013px) {
  .package-card {
    max-width: 30em;
    flex-wrap: nowrap;
  }
}

/* @media (min-width: 1313px) {
  .package-divider-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
  }
  .package-holder {
    position: absolute;
    overflow: scroll;
    top: -moz-calc(60% - (20px + 30px));
    top: -webkit-calc(60% - (20px + 30px));
    top: calc(99% - (60% + 30px));
    left: -moz-calc(100% - (20px + 30px));
    left: -webkit-calc(100% - (20px + 30px));
    left: calc(52% - (20px + 30px));
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.9);
    width: 32%;
    border: 5px solid black;
  }
}
@media (min-width: 1372px) {
  .package-divider-card {
    width: 75em;
    margin: auto;
  }
  .package-margin {
    margin-top: 20em;
  }
  .package-submit {
    width: 75em;
    margin: auto;
    justify-content: flex-end;
  }
} */
