.blog-page p,
.blog-page h2 {
  margin: 0;
}

/* .blogSubHeader {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  text-align: start;
  flex-direction: column;
}

.blogPageHeaderIconRow {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 30px;
  align-items: center;
  margin-top: -1rem;
  color: rgb(0, 0, 0);
}

.blogPageHeaderIconRow > * {
  width: fit-content;
} */

.blogPagePrimaryPage {
  padding: 2rem;
}

/* .blogPagePadding {
  padding: 0rem 3rem 10% 3rem;
} */

.blogPageContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.blogPageContent img {
  border-radius: 25px;
}

.blogPageParaPadding {
  /* margin: 0.5rem; */
  width: 60%;
  font-size: 1.2rem;
  line-height: 1.8;
}

.blogPageContentHeading {
  font-weight: 200;
}

.blogPageParaPadding p {
  margin-bottom: 1rem;
}

@media (max-width: 850px) {
  .blogPageParaPadding {
    width: 90%;
  }
}

@media (max-width: 550px) {
  .blogPagePadding {
    /* padding: 0rem 0.5rem 5% 0.5rem; */
  }

  .blogPageParaPadding {
    width: 99%;
  }
}
