.package-modal {
  margin: auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  display: flex;
  justify-content: left;
  align-self: left;
  position: relative;
}
.close-icon {
  position: sticky;
  height: 18px;
  width: 25px;
  top: 5px;
  left: -20px;
  z-index: 100;
  background-color: #04a8e7;
  color: white;
  padding: 2px;
  border-radius: 99999px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.package-placer {
  padding: 2em 3em;
}
.package-modal-time {
  /* box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%); */
  padding: 0 0.5em;
  margin: auto;
  background-color: #eee;
  color: #03a6ea;
  display: flex;
  border-radius: 0.4rem;
  padding: 0.5rem;
  gap: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  align-items: flex-start;
}
.package-modal-content {
  padding: 1em 0;
}
.package-modal-image img {
  width: 1.2rem;
  /* padding-top: 0.2rem; */
  /* padding: 0.5em; */
}
.package-modal-packages {
  padding: 1rem 0.7rem;
  background-color: #eef7fa;
}

.package-modal-outer-package {
  padding: 1em 0;
  overflow-y: auto;
  max-height: 15em;
  height: auto;
  margin-bottom: 0.5rem;
}
.package-modal-outer-package-heading{
  font-size: large;
  text-transform: uppercase;
  margin: 0;
  margin-top: 1rem;
  background-color: #eee;
  padding: 0.5rem;
  border-radius: 0.3rem;
  text-align: center;
  color: #03a6ea;
}

.package-modal-packages-details {
  display: flex;
  flex-direction: row;
  /* gap: 1rem; */
  justify-content: space-between;
  border-bottom: solid grey 1px;
  padding: 0.3rem 0;
}
.package-modal-sectioncontent {
  flex-basis: 70%;
}
.package-modal-sectionimage.additional {
  margin: 1rem;
}
.package-modal-sectionimage.additional div {
  width: 3.7rem;
  height: 1.5rem;
  background-color: #04a8e7;
  position: relative;
  border-radius: 8px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 0.5rem;
  cursor: pointer;
}
.package-modal-sectionimage.additional img {
  width: 2.4rem;
  position: absolute;
  right: -10px;
  top: -6px;
}
.package-modal-additional {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid #03a6ea;
  padding: 0 1em;
  background-color: #f2fbff;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 2.5rem;
}
.package-modal-timeimage img {
  width: 1.5em;
}
.package-modal-header {
  font-size: 1.5em;
  font-weight: bold;
  color: #04a8e7;
}
.package-modal-total {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
}
.package-modal-button {
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: #17b3f0;
  color: #ffffff;
  border-radius: 25px;
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
}
.package-modal-button img {
  width: 3em;
  padding-right: 1em;
}

.package-card-section {
  display: flex;
  position: relative;
}
.package-card-section img {
  width: 6em;
}

.package-price {
  justify-self: center;
  position: absolute;
  top: 2rem;
  left: 1.25rem;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
}
@media (min-width: 715px) {
  .package-card-section img {
    width: 5em;
  }
}
