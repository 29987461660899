.add-image {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add-image img {
  width: 3em;
}
.addcontainer-text {
  font-weight: bold;
  font-size: 15px;
}
