.app {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  /* background-color: #0bac912d; */
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto;
  height: 100%;
}
