.actions {
  text-align: center;
}

.actions button {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #03577b;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  margin-left: 1rem;
}

.actions button:hover,
.actions button:active {
  background-color: #03577b;
  border-color: #03577b;
  color: white;
}

.actions .button--alt {
  color: #03577b;
}

.actions .button {
  background-color: #03577b;
  color: white;
}
