.signup-background {
  z-index: -1;
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
}

.signup-container {
  position: relative;
}

.signup-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.signup-gradient-base {
  position: relative;
}

.signup-gradient {
  position: relative;
  height: 23em;
}

.signup-header {
  font: normal normal bold 1.7em Open Sans;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}

.signup-placer {
  top: 8em;
  left: 0;
  bottom: 0;
  right: 0;
}

.signup-card {
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 86%;
  height: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background: white;
  padding: 2em;
}

.signup-input {
  font: normal normal normal 16px Open Sans;
  width: 95%;
  height: 32px;
  background: #fff;
  margin: 20px auto;
  padding: 11px;
  border: 1px solid #c5c5c5;
  outline: none;
  border-radius: 12px;
}

.signup-form {
  width: 85%;
  text-align: center;
}

.signup-label {
  padding-left: 1.2em;
  font-weight: bold;
  font-size: 17px;
  color: #17b3f0;
}

.signup-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3em;
}

.signup-sumbit-section {
  width: 9em;
  color: #ffffff;
  font-weight: bold;
  padding-left: 6em;
  cursor: pointer;
  max-width: 15em;
}

.signup-divider-section {
  flex: 1;
}

.signup-divider {
  display: flex;
  flex-direction: column;
}

.image-upload>input {
  display: none;
}

.image-upload img {
  cursor: pointer;
  width: 8em;
}

.image-upload {
  padding-bottom: 2em;
}

.image-upload-placer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.image-placer {
  grid-area: 1/1;
}

.image-placer2 {
  width: 3em !important;
  height: 3em;
  padding-top: 2.5em;
}

.signup-number-field {
  display: flex;
  padding-bottom: 2em;
}

.signup-margin {
  margin-top: 31em;
}

.signup-countrycode {
  width: 2.5em;
  margin-right: 1em;
}

.signup-divider {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1000px) {
  .signup-divider {
    display: flex;
    flex-direction: row;
  }

  .signup-card {
    width: 27em;
  }

  .signup-upload-section {
    width: 24em;
    padding-left: 1em;
  }

  .signup-margin {
    margin-top: 15em;
  }
}

@media (min-width: 500px) and (max-width: 1000px) {
  .signup-divider {
    display: flex;
    flex-direction: column;
  }
}