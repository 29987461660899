.companypage-logo img {
  object-fit: cover;
  width: 180px;
}
.companypage-container {
  padding: 2em 1.5em;
}
.companypage-maincontainer {
  background-color: #f6f6f6;
}
.companypage-main-section {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.companypage-header {
  padding-bottom: 1em;
  font-size: 1.5em;
  font-weight: bold;
}
.companydetail-subsection {
  padding-bottom: 1em;
  font-size: 1.1em;
}
.companydetail-helpcenter {
  font-size: 2em;
  font-weight: bold;
  padding-bottom: 0.5em;
}
.companydetail-follow {
  font-size: 1.2em;
  font-weight: bold;
  padding-bottom: 0.5em;
}
.companydetail-app-wrapper {
  width: 10em;
}
.companydetail-app {
  display: flex;
}
.companydetail-social-wrap {
  padding-right: 1.2em;
}
.companydetail-play {
  padding-right: 0.8em;
}
.a-class {
  color: black;
}
.companydetail-social {
  display: flex;
  padding-bottom: 2em;
}
@media (min-width: 800px) {
  .companypage-container {
    display: flex;
    padding: 0em 4em;
  }
  .companypage-main-section {
    flex: 1;
  }
  .companydetail-sub {
    padding-left: 4em;
  }
  .company-subheader {
    padding-left: 2.5em;
  }
}
@media (min-width: 1000px) {
  .companypage-section {
    padding-right: 5em;
  }
}
@media (min-width: 1500px) {
  .companypage-container {
    width: 90em;
    margin: auto;
  }
}
