/* flowcard */
.flowcard-conatiner {
  position: relative;
  background-color: #0bac912d;
}
.flowcard-mainsection {
  top: 11em;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
.flowcard-section {
  margin: auto;
  width: 51em;
  display: flex;
  justify-content: center;
}
.flowcard-line {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 3.5em;
}
.flowcard-line img {
  width: 35em;
}
.flowcard-image {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  border: 2px solid blanchedalmond;
  height: 5em;
  margin: 0.5em;
  padding: 0.5em;
}
.flowcard-image1 {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 10px;
  background-image: linear-gradient(#0bcef6, #04f9a8);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  border: 2px solid blanchedalmond;
  height: 5em;
  margin: 0.5em;
  padding: 0.5em;
}

.arrow-button {
  width: 0.9em;
}
.flowcard-image img {
  width: 3em;
  object-fit: scale-down;
  margin: 1em;
}
.flowcard-image1 img {
  width: 3em;
  object-fit: scale-down;
  margin: 1em;
}
@media (max-width: 1100px) and (min-width: 750px) {
  .flowcard-section {
    width: 72%;
  }
}
@media (max-width: 750px) {
  .flowcard-section {
    width: 96%;
  }
  .flowcard-line {
    top: 2.6em;
  }
  .flowcard-line img {
    width: 66%;
  }
  .flowcard-image {
    height: 4em;
    padding: 0;
  }
  .flowcard-image1 {
    height: 4em;
    padding: 0;
  }
  .flowcard-image img {
    width: 64%;
    margin: 0;
  }
  .flowcard-image1 img {
    width: 64%;
    margin: 0;
  }
}
