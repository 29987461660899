.SignUpScreen-background {
  z-index: -1;
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
}
.SignUpScreen-container {
  position: relative;
}
.SignUpScreen-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.SignUpScreen-gradient-base {
  position: relative;
}
.SignUpScreen-gradient {
  position: relative;
  height: 23em;
}

.sign-card-header {
  font-weight: bold;
  font-size: 1.7em;
  padding: 0.5em 0em;
}
.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border-right: none;
}

.SignUpScreen-header {
  font: normal normal bold 1.7em Open Sans;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}

.SignUpScreen-card {
  margin: auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 86%;
  height: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background: white;
}
.SignUpScreen-card2 {
  margin: auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 86%;
  height: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background: white;
}

.SignUpScreen-input {
  font: normal normal normal 16px Open Sans;
  width: 89%;
  height: 32px;
  background: #fff;
  margin: 20px auto;
  padding: 11px;
  border: 1px solid #c5c5c5;
  outline: none;
  border-radius: 12px;
}
.SignUpScreen-form {
  padding: 2em;
  height: 100%;
  width: 100%;
}
#recaptcha-container {
  display: flex;
  justify-content: center;
}
.SignUpScreen-label {
  padding-left: 1.2em;
  font-weight: bold;
  font-size: 17px;
  color: #17b3f0;
}
.SignUpScreen-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3em;
}
.SignUpScreen-sumbit-section {
  width: 9em;
  color: #ffffff;
  font-weight: bold;
  padding-left: 6em;
  cursor: pointer;
  max-width: 15em;
}
@media (min-width: 500px) {
  .SignUpScreen-card {
    width: 27em;
    height: auto;
  }
  .SignUpScreen-card2 {
    width: 27em;
    height: auto;
  }
}
