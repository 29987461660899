.detailpagemain-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  background-color: #ffffff;
}
.detail-svgcontainer {
  grid-area: 1/1;
  padding-bottom: 11em;
}
.detailpage-wrapper {
  grid-area: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.para-content ul li {
  padding-bottom: 1em;
}
.detailpage-heading {
  font-size: 1.8em;
  font-weight: bold;
}
.detailpage-button {
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: #058fbc;
  color: #ffffff;
  padding: 0.75rem 3rem;
  border-radius: 25px;
  font-weight: bold;
}
.detailpage-para {
  font-weight: normal;
  font-family: open Sans;
}
.detailpage-content {
  padding: 2em;
}

@media (min-width: 800px) {
  .detailpage-wrapper {
    text-align: unset;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .detail-svgcontainer {
    padding-bottom: 0em;
    width: 60%;
    height: 94%;
  }
  .detailpage-image {
    padding: 1.5em;
    width: 60%;
    height: 91%;
    flex: 1;
  }
  .detail-container {
    padding-top: 1em;
  }
  .detailpage-content {
    flex: 1;
    padding-left: 15em;
  }
  .detailpage-para {
    padding-right: 8em;
  }
  .detailpage-heading {
    padding-bottom: 0.5em;
  }
}
@media (min-width: 800px) and (max-width: 1180px) {
  .detailpage-para {
    padding-right: 3em;
  }
}
@media (min-width: 1500px) {
  .detailpagemain-wrapper {
    width: 90em;
    margin: auto;
  }
}
