.addYourVehicle-background {
  z-index: -1;
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
}

.addYourVehicle-container {
  position: relative;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.addYourVehicle-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.addYourVehicle-gradient-base {
  position: relative;
}

.react-multi-carousel-list {
  margin: 0em 2em;
}

.addYourVehicle-gradient {
  position: relative;
  height: 23em;
}

.addYourVehicle-header {
  font: normal normal bold 1.4em Open Sans;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 1em;
}

.addYourVehicle-card {
  margin: auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 86%;
  height: auto;
  border-radius: 10px;
  background: white;
  padding: 2.5em 1em;
}
.addVehicleborder {
  border: 3px solid #03a6ed;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 30%);
}
.addYourVehicle-margin {
  margin-top: 26em;
}
.addYourVehicle-carbody {
  margin: 1em;
  width: 7em;
  display: flex;
  flex-direction: column;
}

.addYourVehicle-imagesection {
  display: flex;
}

.addYourVehicle-bodytype-header {
  padding: 1em;
}
.input-style{
 display: flex;
 flex-direction: column;
 justify-content: center;
 min-height: 5em;
}

.addYourVehicle-input {
  font: normal normal normal 16px Open Sans;
  width: 81%;
  height: 32px;
  background: #fff;
  margin: auto;
  padding: 11px;
  border: 1px solid #c5c5c5;
  outline: none;
  border-radius: 12px;
}

.addYourVehicle-form {
  text-align: center;
}

.addYourVehicle-label {
  padding-left: 1.2em;
  font-weight: bold;
  font-size: 17px;
  color: #17b3f0;
}

.addYourVehicle-submit {
  padding-top: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 3em;
}

.addYourVehicle-sumbit-section {
  width: 9em;
  color: #ffffff;
  font-weight: bold;
  padding-left: 6em;
}

@media (min-width: 880px) {
  .addYourVehicle-card {
    width: 51em;
    height: auto;
  }

  .addYourVehicle-cardform {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    padding: 1em;
  }
  .addYourVehicle-margin {
    margin-top: 10em;
  }

  .input-style {
    margin: 1em;
  }
  .addYourVehicle-placer {
    margin: auto;
    width: 51em;
  }
  .addYourVehicle-submit {
    width: 53em;
    margin: auto;
    justify-content: space-between;
    flex-direction: row;
  }
}
@media (max-width: 880px) and (min-width: 575px) {
  .addYourVehicle-card {
    width: 31em;
    height: auto;
  }

  .input-style{
    margin: 1em;
  }
}
