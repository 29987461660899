.footer-container {
  display: flex;
  padding: 2em;
  color: #ffffff;
}
.footer-maincontainer {
  background: #02a4ef;
}
.footer-left {
  flex: 1;
}
.footer-right {
  display: flex;
  padding-right: 5em;
}
.footer-span {
  padding: 0em 1em;
}

@media (max-width: 800px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }
  .footer-right {
    padding-top: 1em;
    padding-right: 0em;
  }
}
@media (min-width: 1500px) {
  .footer-container {
    width: 90em;
    margin: auto;
  }
}
