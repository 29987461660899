.edit-delete-dropdown {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}
.edit-delete-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 1.3em;
}
.edit-delete-content {
  padding: 8px 12px;
}
.edit-delete-content:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

/* .dropdown-container {
  position: relative;
  z-index: 4;
} */
