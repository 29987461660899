.addYourLocation-container {
  position: relative;
}

.addYourLocation-header {
  font: normal normal bold 1.4em Open Sans;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}
.addYourLocation-submit {
  padding-top: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 3em;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.addYourLocation-main-header {
  font-size: 1.5em;
  font-weight: bolder;
  text-shadow: 1px 1px black;
  padding-bottom: 1em;
}

.address-header {
  font-weight: bold;
  padding-bottom: 0.5em;
}
.address-main {
  padding: 1em;
  flex: 1;
}

.googlemap-section {
  margin: auto;
  height: 32em;
  padding: 2em;
}
.addYourLocation-outer {
  padding: 1em 2em 2em;
}
.addYourLocation-input {
  width: 100%;
  border-radius: 10px;
}
.address-divider {
  display: flex;
  justify-content: space-between;
}
.addYourLocation-cardform {
  padding-top: 1em;
  padding-bottom: 2em;
  margin: 2em auto;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  height: auto;
  width: 70%;
  border-radius: 10px;
  align-items: center;
  background: white;
  border: 1px solid;
  box-shadow: 1px 1px 1px black;
}
@media (max-width: 930px) {
  .address-divider {
    flex-direction: column;
  }
  .addYourLocation-cardform {
    width: 90%;
  }
}

@media (min-width: 700px) {
  .googlemap-section {
    margin: auto;
    height: 32em;
  }
  .addYourLocation-outer {
    padding: 1em 4em 2em;
  }
  
  .addYourLocation-submit {
    width: 53em;
    margin: auto;
    justify-content: space-between;
    flex-direction: row;
  }
}
